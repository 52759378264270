import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { RolesAuthRoute } from '../hook/RolesAuthRoute';

// ----------------------------------------------------------------------

// OVERVIEW
// const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const IndexPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// CLUSTER
const ClusterListPage = lazy(() => import('src/pages/dashboard/cluster/list'));
const ClusterCreatePage = lazy(() => import('src/pages/dashboard/cluster/new'));
const ClusterEditPage = lazy(() => import('src/pages/dashboard/cluster/edit'));
// HUT
const HutListPage = lazy(() => import('src/pages/dashboard/hut/list'));
const HutCreatePage = lazy(() => import('src/pages/dashboard/hut/new'));
const HutEditPage = lazy(() => import('src/pages/dashboard/hut/edit'));
// FAQ
const FaqListPage = lazy(() => import('src/pages/dashboard/faq/list'));
const FaqCreatePage = lazy(() => import('src/pages/dashboard/faq/new'));
const FaqEditPage = lazy(() => import('src/pages/dashboard/faq/edit'));
// TICKET
const TicketListPage = lazy(() => import('src/pages/dashboard/ticket/list'));
const TicketCreatePage = lazy(() => import('src/pages/dashboard/ticket/new'));
const TicketEditPage = lazy(() => import('src/pages/dashboard/ticket/edit'));
// SALES DATA
const SalesDataListPage = lazy(() => import('src/pages/dashboard/salesData/list'));
const SalesDataCreatePage = lazy(() => import('src/pages/dashboard/salesData/new'));
const SalesDataEditPage = lazy(() => import('src/pages/dashboard/salesData/edit'));
// Mushroom Type
const MushroomTypeListPage = lazy(() => import('src/pages/dashboard/mushroomType/list'));
const MushroomTypeCreatePage = lazy(() => import('src/pages/dashboard/mushroomType/new'));
const MushroomTypeEditPage = lazy(() => import('src/pages/dashboard/mushroomType/edit'));
// NEW NOTIFICATION
const NewNotificationCreatePage = lazy(() => import('src/pages/dashboard/newNotification/new'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
const UserViewPage = lazy(() => import('src/pages/dashboard/user/view'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      { path: 'profile', element: <UserAccountPage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['super_admin', 'cluster_admin']}>
                <UserCreatePage />
              </RolesAuthRoute>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <RolesAuthRoute roles={['super_admin', 'cluster_admin']}>
                <UserEditPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: ':id/view',
            element: (
              <RolesAuthRoute roles={['super_admin', 'cluster_admin']}>
                <UserViewPage />
              </RolesAuthRoute>
            ),
          },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'cluster',
        children: [
          { element: <ClusterListPage />, index: true },

          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['super_admin', 'cluster_admin', 'group_admin']}>
                <ClusterListPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <ClusterCreatePage />
              </RolesAuthRoute>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <ClusterEditPage />
              </RolesAuthRoute>
            ),
          },
        ],
      },
      {
        path: 'hut',
        children: [
          { element: <HutListPage />, index: true },
          { path: 'list', element: <HutListPage /> },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <HutCreatePage />
              </RolesAuthRoute>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <HutEditPage />
              </RolesAuthRoute>
            ),
          },
        ],
      },
      {
        path: 'faq',
        children: [
          { element: <FaqListPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <FaqListPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <FaqCreatePage />
              </RolesAuthRoute>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <FaqEditPage />
              </RolesAuthRoute>
            ),
          },
        ],
      },
      {
        path: 'ticket',
        children: [
          { element: <TicketListPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['super_admin', 'cluster_admin', 'group_admin']}>
                <TicketListPage />
              </RolesAuthRoute>
            ),
          },
          { path: 'new', element: <TicketCreatePage /> },
          { path: ':id/edit', element: <TicketEditPage /> },
        ],
      },
      {
        path: 'salesData',
        children: [
          { element: <SalesDataListPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <SalesDataListPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <SalesDataCreatePage />
              </RolesAuthRoute>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <SalesDataEditPage />
              </RolesAuthRoute>
            ),
          },
        ],
      },
      {
        path: 'mushroomType',
        children: [
          { element: <MushroomTypeListPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <MushroomTypeListPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <MushroomTypeCreatePage />
              </RolesAuthRoute>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <RolesAuthRoute roles={['super_admin']}>
                <MushroomTypeEditPage />
              </RolesAuthRoute>
            ),
          },
        ],
      },
      {
        path: 'sendNotification',
        element: (
          <RolesAuthRoute roles={['super_admin']}>
            <NewNotificationCreatePage />
          </RolesAuthRoute>
        ),
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
